import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  homepageContainer: {
    position: 'fixed', // Ensures it stays on top
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black with 80% opacity for semi-transparency
    zIndex: 9999, // Ensure it's above other content
  },
  headerText: {
    fontSize: '2.5rem',
    color: '#fff', // White text
    marginBottom: '30px',
    fontFamily: "'Poppins', sans-serif", // Modern font, you can change it if needed
  },
  buttonsContainer: {
    display: 'flex',
    gap: '15px', // Adjust gap between buttons
    justifyContent: 'center',
    textAlign: 'center',
    
    // Media query for small devices in portrait mode
    [theme.breakpoints.down('sm')]: {
      '@media (orientation: portrait)': {
        flexDirection: 'column',
      },
    },
  },
  homepageButton: {
    padding: '15px 30px',
    fontSize: '1.5rem', // Increased font size for larger buttons
    width: '200px',
    height: '200px', // Default height

    borderRadius: '15px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      opacity: 0.9,
    },

    // Media query for portrait orientation and small devices
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  },
  buttonPodcast: {
    backgroundColor: '#e3f2fd', // Subtle blue
  },
  buttonTokenGraph: {
    backgroundColor: '#f3e5f5', // Subtle purple
  },
  buttonNFTViewer: {
    backgroundColor: '#fff3e0', // Subtle orange
  },
  buttonNewsletter: {
    backgroundColor: '#e0f7fa', // Subtle cyan
  },
}));

const HomePage = ({ setShowHomePage }) => {
  const classes = useStyles();

  // Functions that handle the navigation for each button click
  const goToPodcast = () => {
    const podcast = new Audio('/E3D_Podcast.wav');
    podcast.play().catch(error => {
      console.error('Failed to play podcast:', error);
    });
  };

  const goToTokenGraph = () => {
    console.log('Go to 3D Token Graph');
    // Insert actual navigation or view-switching logic here
  };

  const goToNFTViewer = () => {
    window.location.href = 'https://e3d.ai/nft';
  };

  const goToNewsletter = () => {
    window.location.href = 'https://e3d.ai?news';
  };

  const handleButtonClick = (navigationFunction) => {
    setShowHomePage(false);
    navigationFunction();
  };

  const handleBackgroundClick = () => {
    setShowHomePage(false);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={classes.homepageContainer} onClick={handleBackgroundClick}>
      <div className={classes.headerText}>Let's get started!</div>
      <div className={classes.buttonsContainer} onClick={stopPropagation}>
        <button
          className={`${classes.homepageButton} ${classes.buttonTokenGraph}`}
          onClick={() => handleButtonClick(goToTokenGraph)}
        >
          3D Token Graph
        </button>
        <button
          className={`${classes.homepageButton} ${classes.buttonNFTViewer}`}
          onClick={() => handleButtonClick(goToNFTViewer)}
        >
          NFT Group Viewer
        </button>
        <button
          className={`${classes.homepageButton} ${classes.buttonNewsletter}`}
          onClick={() => handleButtonClick(goToNewsletter)}
        >
          E3D AI Newsletter
        </button>
        <button
          className={`${classes.homepageButton} ${classes.buttonPodcast}`}
          onClick={() => handleButtonClick(goToPodcast)}
        >
          E3D AI Podcast
        </button>
      </div>
    </div>
  );
};

export default HomePage;
